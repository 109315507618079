import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Layout from "../components/new/Layout";
import Spacer from "../components/new/Spacer";
import RichTextBlock from "../components/new/blocks/RichTextBlock";

const HygienePage = ({ pageContext }) => {
  const content = pageContext.pageData;
  const meta = pageContext.meta;

  if (!content) {
    return null;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.page_title}
        titleTemplate={`%s | IPG - DXTRA`}
        meta={[
          {
            name: `description`,
            content: content.meta_description || meta.description,
          },
          {
            property: `og:title`,
            content: content.meta_title,
          },
          {
            property: `og:description`,
            content: content.meta_description ||meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: content.page_title || meta.title,
          },
          {
            name: `twitter:description`,
            content: content.meta_description ||meta.description,
          },
        ].concat(meta)}
      />
      <Layout navigationTransparent={false}>
        <Grid>
          <Row>
            <Col xs={12} mdOffset={1} md={10} lgOffset={2} lg={8}>
              <Spacer padding={{ xs: "80px 0 40px", m: "180px 0 80px" }}>
                <RichTextBlock
                  heading={content.page_title}
                  date={content.date}
                  body={content.body_copy}
                />
              </Spacer>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </>
  );
};

HygienePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HygienePage;
